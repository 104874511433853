// 01/09/25
const dateNumericOptions: Intl.DateTimeFormatOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
};

// 01 JUL 2025
const dateShortOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
};

// 1 July 2025
const dateLongOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

const dateTimeOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

export const dateToLondonNumericDateString = (date?: Date | null) => {
  return date
    ? date.toLocaleDateString('en-GB', {
        ...dateNumericOptions,
        timeZone: 'Europe/London',
      })
    : '';
};

export const dateToLondonShortDateString = (date?: Date | null) => {
  return date
    ? date
        .toLocaleDateString('en-GB', {
          ...dateShortOptions,
          timeZone: 'Europe/London',
        })
        .toLocaleUpperCase()
    : '';
};

export const dateToLondonLongDateString = (date?: Date | null) => {
  return date
    ? date.toLocaleDateString('en-GB', {
        ...dateLongOptions,
        timeZone: 'Europe/London',
      })
    : '';
};

export const dateToLondonDateTimeString = (date?: Date | null) => {
  return date
    ? date.toLocaleDateString('en-GB', {
        ...dateTimeOptions,
        timeZone: 'Europe/London',
      })
    : '';
};

export const dateTimeToLondonDateTimeString = (date?: Date | null) => {
  return date
    ? date.toLocaleDateString('en-GB', {
        ...dateTimeOptions,
        timeZone: 'Europe/London',
      })
    : '';
};

export const dateTimeToLocalDateTimeString = (date?: Date | null) => {
  return date ? date.toLocaleDateString(undefined, dateTimeOptions) : '';
};

export const dateToLocaleDateString = (date?: Date) => {
  return date ? date.toLocaleDateString('en-GB', dateLongOptions) : '';
};

export const latestDayTime = (date: Date) =>
  new Date(date.setHours(23, 59, 59, 999));

export const earliestDayTime = (date: Date) =>
  new Date(date.setHours(0, 0, 0, 0));

export const localTimeZone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;
