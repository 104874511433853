import { bool, date, InferType, number, object, string } from 'yup';

export const EbpCaseSupportAuthorizationSchema = object().shape({
  requested: bool(),
  rejected: bool(),
  notRequired: bool(),
});

export type EbpCaseSupportAuthorization = InferType<
  typeof EbpCaseSupportAuthorizationSchema
>;

export const UpdateEbpCaseSupportAuthorizationLevelOneSchema = object().shape({
  endDate: date().required(),
});

export const UpdateEbpCaseSupportAuthorizationLevelTwoSchema = object().shape({
  endDate: date().required(),
  maxAppointments: number().required(),
});

export const UpdateEbpCaseSupportAuthorizationLevelThreeSchema = object().shape(
  {
    maxAppointments: number().required(),
  },
);

export const UpdateEbpCaseSupportAuthorizationRejectedSchema = object().shape({
  notes: string(),
});

export const UpdateEbpCaseSupportAuthorizationNotRequiredSchema =
  object().shape({
    notes: string(),
  });

export type UpdateEbpCaseSupportAuthorizationLevelOne = InferType<
  typeof UpdateEbpCaseSupportAuthorizationLevelOneSchema
>;

export type UpdateEbpCaseSupportAuthorizationLevelTwo = InferType<
  typeof UpdateEbpCaseSupportAuthorizationLevelTwoSchema
>;

export type UpdateEbpCaseSupportAuthorizationLevelThree = InferType<
  typeof UpdateEbpCaseSupportAuthorizationLevelThreeSchema
>;

export type UpdateEbpCaseSupportAuthorizationRejected = InferType<
  typeof UpdateEbpCaseSupportAuthorizationRejectedSchema
>;

export type UpdateEbpCaseSupportAuthorizationNotRequired = InferType<
  typeof UpdateEbpCaseSupportAuthorizationNotRequiredSchema
>;
