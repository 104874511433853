import { ServiceInfo } from '../schemas/ServiceInfo';

export const CancerNurseSpecialistServiceId = 7;

const servicesProd: Record<string, ServiceInfo> = {
  '93': {
    name: 'Clinical exercise coaching',
    healee_ref: 'exercise-coach',
    professional_type: 'Clinical exercise coach',
  },
  '1': {
    name: 'Dietetics',
    healee_ref: 'dietitian',
    professional_type: 'Dietitian',
  },
  '76': {
    name: 'Hypnotherapy',
    healee_ref: 'hypnotherapist',
    professional_type: 'Hypnotherapist',
  },
  '78': {
    name: 'Image advisor',
    healee_ref: 'image-advisors',
    professional_type: 'Image advisor',
  },
  '79': {
    name: 'Life coaching',
    healee_ref: 'life-coach',
    professional_type: 'Life coaching',
  },
  '88': {
    name: 'Lymphoedema nurse',
    healee_ref: 'lymphoedema-practitioner',
    professional_type: 'Lymphoedema practitioner',
  },
  '3': {
    name: 'Menopause',
    healee_ref: 'menopause',
    professional_type: 'Menopause specialist',
  },
  '89': {
    name: 'Mindfulness and meditation',
    healee_ref: 'mindfulness-and-meditation-professionals',
    professional_type: 'Mindfulness and meditation professional',
  },
  '96': {
    name: 'Occupational therapy',
    healee_ref: 'occupational-therapy',
    professional_type: 'Occupational therapist',
  },
  '4': {
    name: 'Physiotherapy',
    healee_ref: 'physiotherapist',
    professional_type: 'Physiotherapist',
  },
  '91': {
    name: 'Pilates instructor',
    healee_ref: 'cancer-pilates-instructors',
    professional_type: 'Pilates instructor',
  },
  '5': {
    name: 'Psychology',
    healee_ref: 'psychologist',
    professional_type: 'Psychologist',
  },
  '6': {
    name: 'Psychosexual therapy',
    healee_ref: 'psychosexual-therapist',
    professional_type: 'Psychosexual therapist',
  },
  '94': {
    name: 'Psychotherapy',
    healee_ref: 'psychotherapist',
    professional_type: 'Psychotherapist',
  },
  [CancerNurseSpecialistServiceId.toString()]: {
    name: 'Cancer nurse specialist',
    healee_ref: 'cancer-nurse-specialists',
    professional_type: 'Cancer nurse specialist',
  },
  '74': {
    name: 'Speech and language therapy',
    healee_ref: 'speech-and-language',
    professional_type: 'Speech and language therapist',
  },
  '75': {
    name: 'Yoga instructor',
    healee_ref: 'yoga-instructor',
    professional_type: 'Yoga instructor',
  },

  // Disabled on purpose, see https://app.clickup.com/t/p9fucv
  // '77': {
  //   name: 'Cancer coaching',
  //   healee_ref: 'cancer-coach',
  //   professional_type: 'Cancer coach',
  // },
  // '82': { name: 'Cancer gynaecological nurse', healee_ref: 'GSCN' },
  // '83': { name: 'Cancer breast nurse', healee_ref: 'breast-specialist-cancer-nurse' },
  // '86': { name: 'Cancer gastrointestinal nurse', healee_ref: 'gastrointestinal-cancer-nurse-specialist' },
  // '84': { name: 'Reconnection therapy', healee_ref: 'reconnection-therapy' },
  //'81': { name: 'Family history nurse specialist', healee_ref: 'FHNS' },
  //'80': { name: 'Genetic counsellor', healee_ref: 'genetics' },
};

const servicesDev: Record<string, ServiceInfo> = {
  '1': {
    name: 'Dietetics',
    healee_ref: 'dietitian',
    professional_type: 'Dietitian',
  },
  '76': {
    name: 'Hypnotherapy',
    healee_ref: 'hypnotherapist',
    professional_type: 'Hypnotherapist',
  },
  '6': {
    name: 'Psychosexual therapy',
    healee_ref: 'psychosexual-therapist',
    professional_type: 'Psychosexual therapist',
  },
  '77': {
    name: 'Cancer coaching',
    healee_ref: 'cancer-coach',
    professional_type: 'Cancer coach',
  },
  // without professionals:
  '2': {
    name: 'Hair and image advice',
    healee_ref: 'hair-image-advisor',
    professional_type: 'Hair image advisor',
  },
  '78': {
    name: 'Image and style advice',
    healee_ref: 'image-advisor',
    professional_type: 'Image advisor',
  },
  '3': {
    name: 'Menopause',
    healee_ref: 'menopause',
    professional_type: 'Menopause specialist',
  },
  '4': {
    name: 'Physiotherapy',
    healee_ref: 'physiotherapist',
    professional_type: 'Physiotherapist',
  },
  '5': {
    name: 'Psychology',
    healee_ref: 'psychologist',
    professional_type: 'Psychologist',
  },
  [CancerNurseSpecialistServiceId.toString()]: {
    name: 'Cancer specialist nurse',
    healee_ref: 'cancer-nurse',
    professional_type: 'Cancer nurse specialist',
  },
  '74': {
    name: 'Speech and language therapy',
    healee_ref: 'speech-and-language',
    professional_type: 'Speech and language therapist',
  },
  '75': {
    name: 'Yoga and meditation',
    healee_ref: 'yoga-instructor',
    professional_type: 'Yoga instructor',
  },
};

export const services = {
  dev: servicesDev,
  prod: servicesProd,
};
