import { default as svg } from './logo.svg';
import { Link } from 'react-router-dom';
import styles from './Header.module.css';
import SingedIn from './SignedIn/SignedIn';
import { classList } from '@percihealth/react';

export default function Header() {
  return (
    <header className={classList('grid-outer', styles.header)}>
      <Link to="/">
        <img src={svg} className={styles.img} />
      </Link>
      <div className={styles['right-block']}>
        <SingedIn />
      </div>
    </header>
  );
}
