import { bool, InferType, mixed, object, string } from 'yup';
import {
  NegativeReturnedToWorkRationale,
  PositiveReturnedToWorkRationale,
  ReturnedToWorkRationale,
} from '../enums';

export const EbpCaseReturnedToWorkSchema = object().shape({
  returned: bool().required(),
  rationale: mixed<ReturnedToWorkRationale>()
    .oneOf([
      ...Object.values(PositiveReturnedToWorkRationale),
      ...Object.values(NegativeReturnedToWorkRationale),
    ])
    .required(),
  notes: string(),
});

export type EbpCaseReturnedToWork = InferType<
  typeof EbpCaseReturnedToWorkSchema
>;
