import { InferType, array, date, mixed, object, string } from 'yup';
import { InterimCareSummarySupportStatus } from '../../../enums';

export const InterimCareSummarySupportProgressSchema = object().shape({
  service: object()
    .shape({
      id: string().required(),
      name: string().required(),
      professionalType: string(),
    })
    .required(),

  progress: string(),

  appointments: array()
    .of(
      object().shape({
        id: string().required('Appointment status is required'),
        date: date().nullable().optional(),
        status: mixed<InterimCareSummarySupportStatus>()
          .oneOf(Object.values(InterimCareSummarySupportStatus))
          .required('Appointment status is required'),
      }),
    )
    .required(),
});

export type InterimCareSummarySupportProgress = InferType<
  typeof InterimCareSummarySupportProgressSchema
>;
