import { InferType, number, object, string } from 'yup';
import { IObject } from '../IObject';

export const EbpCaseExpertUpsertNoteSchema = object().shape({
  note: string().max(5000).required('Note is required'),
  timeTakenInMinutes: number()
    .min(1, "Time taken can't be less than 1 minute")
    .max(1000, "Time taken can't be more than 1000 minutes")
    .required('Time taken is required'),
});

export const EbpCaseExpertInternalNoteDataSchema =
  EbpCaseExpertUpsertNoteSchema.shape({
    expertId: string().max(150).required(),
  });

export type EbpCaseExpertUpsertNote = InferType<
  typeof EbpCaseExpertUpsertNoteSchema
>;

export type EbpCaseExpertInternalNoteData = InferType<
  typeof EbpCaseExpertInternalNoteDataSchema
>;
export type EbpCaseExpertInternalNote =
  IObject.HasIdTimestamped<EbpCaseExpertInternalNoteData>;
