import { InferType, object, string } from 'yup';

export const InterimSummaryExpertSummarySchema = object().shape({
  motivation: string().required(
    'Motivation and programme engagement is required',
  ),
});

export type InterimSummaryExpertSummary = InferType<
  typeof InterimSummaryExpertSummarySchema
>;
