import { object, string, date, array, InferType } from 'yup';
import { nameValidation } from '@percihealth/core-js';
import { IamCancerSchema } from '../../../IamCancerSchema';
import { CareDocumentResourceSchema } from '../CareDocumentResourceSchema';
import { CareDocumentDataSchema } from '../CareDocumentDataSchema';
import { IObject } from '../../../IObject';
import { PcpPatientServiceListSchema } from './PcpPatientServiceListSchema';

export const PcpDataSchema = CareDocumentDataSchema.shape({
  patient: object().shape({
    fullName: nameValidation.required('Patient name is required'),
    dob: date().required('Patient date of birth is required'),
    iam: IamCancerSchema.required("'I am' is a required"),
  }),
  gip: object()
    .shape({
      code: string(),
    })
    .optional()
    .default(undefined),
  expertFullName: string(),
  expertPhotoUrl: string().optional().nullable().default(undefined),
  dateOfConsultation: date().required('Date of consultation is required'),
  notes: string().required(),
  resources: array().of(CareDocumentResourceSchema).required(),
  patientServices: PcpPatientServiceListSchema,
  cancerImpact: object()
    .shape({
      concern1: object().shape({
        concern: string().nullable(),
        level: string().nullable(),
      }),
      concern2: object().shape({
        concern: string().nullable(),
        level: string().nullable(),
      }),
      feelingLevel: string().nullable(),
      psychologicalImpacts: array().of(string().required()),
      physicalImpacts: array().of(string().required()),
      affectedRelationships: array().of(string().required()),
    })
    .optional(),
});

export type PcpData = InferType<typeof PcpDataSchema>;
export type Pcp = IObject.HasIdTimestamped<PcpData>;
