import { InferType, mixed, object, string } from 'yup';
import { AppointmentReferralReviewStatus } from '../enums/AppointmentReferralReviewStatus';

export const AppointmentReferralReviewSchema = object().shape({
  status: mixed<AppointmentReferralReviewStatus>()
    .oneOf(Object.values(AppointmentReferralReviewStatus))
    .required(),
  notes: string().required(),
});

export type AppointmentReferralReview = InferType<
  typeof AppointmentReferralReviewSchema
>;
