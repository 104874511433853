import { InferType, object, string } from 'yup';

export const CareDocumentOvercomeChallengeRecommendationSchema = object().shape(
  {
    recommendation: string().required('Recommendation is required'),
    timeframe: string().required('Timeframe is required'),
  },
);

export type CareDocumentOvercomeChallengeRecommendation = InferType<
  typeof CareDocumentOvercomeChallengeRecommendationSchema
>;
