import { InferType, object, string } from 'yup';

export const CareDocumentTreatmentDetailsSchema = object().shape({
  treatment: string().required('Treatment to date is required'),
  plan: string().required('Ongoing treatment plan is required'),
  expectedEndDate: string().required('Expected treatment end date is required'),
  other: string().required('Other medical relevant history is required'),
});

export type CareDocumentTreatmentDetails = InferType<
  typeof CareDocumentTreatmentDetailsSchema
>;
