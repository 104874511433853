import { default as svg } from './professional.svg';
import styles from './NextSteps.module.css';
import { classList } from '@percihealth/react';

export const ReferralNextSteps = () => {
  return (
    <div className={classList('grid-outer', styles['outer-container'])}>
      <div className={classList('grid-content', styles.container)}>
        <div className={styles['img-container']}>
          <img src={svg} className={styles.img} />
        </div>
        <div className={styles['text-container']}>
          <h2>What happens next</h2>
          <p>
            Once you have filled in this online referral form or emailed us with
            it, we will send you a confirmation email for your records. We will
            then:
          </p>
          <ol className="colored-numbers">
            <li>
              <b>Email your patient</b> to let them know that we have received
              your referral.
            </li>
            <li>
              Include a <b>link to the service or professional you referred</b>{' '}
              them to.
            </li>
            <li>
              <b>Keep you updated</b> on their progress after we have met with
              them.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};
