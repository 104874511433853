import { emailValidation } from '@percihealth/core-js';
import {
  array,
  boolean,
  date,
  InferType,
  mixed,
  number,
  object,
  string,
} from 'yup';
import { AppointmentConclusionSchema } from './AppointmentConclusionSchema';
import { AppointmentStatus } from '../enums/AppointmentStatus';
import { IObject } from '../IObject';
import { AppointmentTranscriptionSchema } from './AppointmentTranscriptionSchema';

export const AppointmentDataSchema = object().shape({
  status: mixed<AppointmentStatus>()
    .oneOf(Object.values(AppointmentStatus))
    .required(),
  services: array().of(number()),
  patientEmail: emailValidation,
  date: date().nullable().optional(),
  previousAppointmentDates: array()
    .of(
      object().shape({
        date: date().nullable().optional(),
        rescheduled: date(),
        by: string(),
      }),
    )
    .optional(),
  doctor: string().max(150),
  conclusion: AppointmentConclusionSchema.default(undefined),
  cancelledAt: date(),

  followedUp: boolean(),
  noShow: boolean(),

  // storage references
  transcriptions: array().of(AppointmentTranscriptionSchema).default(undefined),
});

export const AppointmentSchema = AppointmentDataSchema.shape({
  id: string().required(),
  createdAt: date().required(),
});

export type AppointmentData = InferType<typeof AppointmentDataSchema>;

export type Appointment = IObject.HasIdTimestamped<
  InferType<typeof AppointmentSchema>
>;
