export const getFetchResponseErrorMessage = async (response: Response) => {
  try {
    const data = await response.json();
    if (data?.error) {
      return data.error;
    } else if (data?.errors) {
      return (data.errors as string[]).join(', ');
    }
  } catch {
    // intentionally left blank
  }

  // default fallback
  return `${response.status} ${response.statusText}`;
};
