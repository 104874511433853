import { object, string, boolean, InferType, array } from 'yup';
import { ProfessionalInfo } from '../../Professional';
import { payments } from '../../../data';
import { ReferralPatientSchema } from '../ReferralPatientSchema';
import { ReferrerSchema } from '../ReferrerSchema';
import { IObject } from '../../IObject';
import { ReferralBaseSchema } from '../ReferralBaseSchema';

// Used to create a referral from the website UI
export const ReferralCreateDefaultSchema = (
  serviceIds: string[],
  professionals: Record<string, ProfessionalInfo>,
) => {
  return object().shape({
    patient: ReferralPatientSchema,
    payment: string().required().oneOf(payments),
    referrer: ReferrerSchema.shape({
      id: string(),
    }),
    referral: object().shape({
      serviceId: string().required().oneOf(serviceIds),
      hasPreferredProfessional: boolean().required(),
      preferredProfessionalId: string().when('hasPreferredProfessional', {
        is: true,
        then: (schema) =>
          schema.when(
            ['hasPreferredProfessional', 'serviceId'],
            ([hasPreferredProfessional, serviceId], profSchema) =>
              hasPreferredProfessional
                ? profSchema
                    .required(
                      'Preferred professional is required when selected "Yes" in the previous field',
                    )
                    .test(
                      'professionals_test',
                      'Preferred professional should be selected from the available list',
                      (value) =>
                        Object.keys(professionals).filter(
                          (key) =>
                            key === value &&
                            professionals[key].services.includes(serviceId),
                        ).length > 0,
                    )
                : profSchema,
          ),
        otherwise: (schema) => schema,
      }),
    }),
    acceptForm: boolean()
      .required()
      .oneOf([true], 'Please confirm having the consent'),
  });
};

const EmptyInputReferralSchema = ReferralCreateDefaultSchema([], {});
export type CreateDeafaultReferral = InferType<typeof EmptyInputReferralSchema>;

// Referral as it is stored in the database
export const ReferralDefaultSchema = ReferralBaseSchema.shape({
  patient: ReferralPatientSchema,
  payment: string().required().oneOf(payments),
  referrer: ReferrerSchema,

  preferredProfessional: object()
    .shape({
      id: string().required(),
      fullname: string().required(),
      services: array(string().required()).required(),
    })
    .default(undefined),
});

export type ReferralDefaultData = InferType<typeof ReferralDefaultSchema>;
export type ReferralDefault = IObject.HasIdTimestamped<ReferralDefaultData>;
