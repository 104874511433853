import { InferType, string } from 'yup';
import { IObject } from '../IObject';
import { EmailBaseSchema } from '../emails';

export const ReferralEmailSchema = EmailBaseSchema.shape({
  referralId: string(),
});

export type ReferralEmailData = InferType<typeof ReferralEmailSchema>;

export type ReferralEmail = IObject.HasIdTimestamped<ReferralEmailData>;
