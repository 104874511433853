import { ReferralCancelReasonKey } from '../schemas/enums';

export const referralCancelReasons = [
  { key: ReferralCancelReasonKey.cost, value: 'of high cost for the client' },
  {
    key: ReferralCancelReasonKey.no_response,
    value: 'the member did not book or respond',
  },
  {
    key: ReferralCancelReasonKey.incorrect_details,
    value: 'the referral has incorrect details',
  },
  {
    key: ReferralCancelReasonKey.seen_elsewhere,
    value: 'your patient informed us that they have been seen elsewhere',
  },
  {
    key: ReferralCancelReasonKey.no_wish_to_proceed,
    value:
      'your patient informed us that they do not wish to proceed with your referral',
  },
  {
    key: ReferralCancelReasonKey.referral_out_of_scope,
    value: 'the referral falls outside of our scope of practice',
  },
];
