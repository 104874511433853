import { object, string, date, array, InferType, bool } from 'yup';
import { nameValidation } from '@percihealth/core-js';
import { IamCancerSchema } from '../../../IamCancerSchema';
import { CareDocumentDataSchema } from '../CareDocumentDataSchema';
import { CareDocumentColumnSchema } from '../CareDocumentColumnSchema';
import { CareDocumentOvercomeChallengeSchema } from '../CareDocumentOvercomeChallengeSchema';
import { IObject } from '../../../IObject';

export const WorkFocusedPlanDataSchema = CareDocumentDataSchema.shape({
  patient: object().shape({
    fullName: nameValidation.required('Patient name is required'),
    dob: date().required('Patient date of birth is required'),
    iam: IamCancerSchema.required("'I am' is a required"),
    work: object().shape({
      jobTitle: string().required('Job title is required'),
      dateSignedOff: date().required('Date signed off is required'),
      workPattern: string().required('Work pattern is required'),
      hideHopingToReturnToWorkIn: bool().required(),
      hopingToReturnToWorkIn: string().when('hideHopingToReturnToWorkIn', {
        is: false,
        then: (schema) =>
          schema.required('Hoping to return to work is required'),
      }),
    }),
  }),
  gip: object().shape({
    code: string().required('GIP code is required'),
  }),
  workImpact: array().of(CareDocumentColumnSchema).required(),
  planToOvercomeChallenges: array()
    .of(CareDocumentOvercomeChallengeSchema)
    .required(),
  expertFullName: string(),
  dateOfConsultation: date().required('Date of consultation is required'),
});

export type WorkFocusedPlanData = InferType<typeof WorkFocusedPlanDataSchema>;
export type WorkFocusedPlan = IObject.HasIdTimestamped<WorkFocusedPlanData>;
