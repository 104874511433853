import { InferType, bool, object } from 'yup';

export const ReferralOrganizationEmailOptionsSchema = object().shape({
  skipReminderOne: bool(),
  skipReminderTwo: bool(),
});

export type ReferralOrganizationEmailOptions = InferType<
  typeof ReferralOrganizationEmailOptionsSchema
>;
