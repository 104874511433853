import { InferType, object, string } from 'yup';
import { IObject } from './IObject';
import { IdSchema } from './IdSchema';

export const OrganizationSchema = object().shape({
  id: IdSchema,
  name: string().required(),
});

export type OrganizationData = InferType<typeof OrganizationSchema>;
export type Organization = IObject.HasIdTimestamped<OrganizationData>;
