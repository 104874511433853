import { InferType, date, object, string } from 'yup';
import { HealeeMedicalHistoryPatientAddressSchema } from './HealeeMedicalHistoryPatientAddressSchema';

export const HealeeMedicalHistoryPatientSchema = object().shape({
  dateOfBirth: date(),
  address: HealeeMedicalHistoryPatientAddressSchema.optional()
    .nullable()
    .default(undefined),
  cancerType: string(),
  gpPracticeName: string(),
  // When it was sent to Healee
  at: date(),
  by: string(),
});

export type HealeePatientMedicalHistory = InferType<
  typeof HealeeMedicalHistoryPatientSchema
>;
