import { InferType, object, string } from 'yup';

export const HealeeMedicalHistoryPatientAddressSchema = object().shape({
  city: string(),
  line1: string(),
  line2: string(),
  postCode: string(),
  country: string(),
});

export type HealeeMedicalHistoryPatientAddress = InferType<
  typeof HealeeMedicalHistoryPatientAddressSchema
>;
