import { object, string, date, InferType } from 'yup';
import { CareDocumentDataSchema } from './CareDocumentDataSchema';
import { IObject } from '../../IObject';

export const HealixCareDocumentDataSchema = CareDocumentDataSchema.shape({
  sentToHealixNurse: object()
    .shape({
      at: date().required(),
      by: string().nullable(),
    })
    .optional()
    .default(undefined),
});

export type HealixCareDocumentData = InferType<
  typeof HealixCareDocumentDataSchema
>;
export type HealixCareDocument =
  IObject.HasIdTimestamped<HealixCareDocumentData>;
