export enum CareDocumentType {
  PersonalisedCarePlan = 'pcp',
  WorkFocusedPlan = 'work-focused-plan',
  EndOfCareSummary = 'end-of-care-summary',
  InterimCareSummary = 'interim-care-summary',
  InitialAssessmentSummary = 'initial-assessment-summary',
}

export function displayEbpDocumentType(type: CareDocumentType) {
  switch (type) {
    case CareDocumentType.PersonalisedCarePlan:
      return 'Personalised Care Plan';
    case CareDocumentType.WorkFocusedPlan:
      return 'Work Focused Plan';
    case CareDocumentType.EndOfCareSummary:
      return 'End of Care Summary';
    case CareDocumentType.InterimCareSummary:
      return 'Interim Care Summary';
    case CareDocumentType.InitialAssessmentSummary:
      return 'Initial Assessment Summary';
    default:
      throw new Error(`Unknown EBP document type: ${type}`);
  }
}
