import { CareDocumentCheckableItemSchema } from '../CareDocumentCheckableItemSchema';
import { InferType, object } from 'yup';

export const EndOfCareSummaryReturnToWorkSchema = object().shape({
  returnToWork: CareDocumentCheckableItemSchema.required(),
  returnToWorkDateAgreed: CareDocumentCheckableItemSchema.required(),
  intendsToReturnToWork: CareDocumentCheckableItemSchema.required(),
  caseManagerReview: CareDocumentCheckableItemSchema.required(),
});

export type EndOfCareSummaryReturnToWork = InferType<
  typeof EndOfCareSummaryReturnToWorkSchema
>;

export const isReturnToWorkStatusEmpty = (rtw: EndOfCareSummaryReturnToWork) =>
  !rtw.caseManagerReview.checked &&
  !rtw.intendsToReturnToWork.checked &&
  !rtw.returnToWork.checked &&
  !rtw.returnToWorkDateAgreed.checked;
