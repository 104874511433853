import { object, string, InferType, number } from 'yup';
import { ReferralPatientSchema } from '../ReferralPatientSchema';
import { IObject } from '../../IObject';
import { ReferralBaseSchema } from '../ReferralBaseSchema';
import { ReferrerSchema } from '../ReferrerSchema';
import { OrganizationSchema } from '../../OrganizationSchema';
import { ReferralGenericDocumentsSchema } from './ReferralGenericDocumentsSchema';

const ReferralBaseGenericSchemaObject = {
  // ID is required for L&G referrals to be passed from UI
  // because a referrer uploads documents to the storage "referrals/{generated-referral-id}/document.pdf"
  id: string().required(),
  patient: ReferralPatientSchema,
  joinReasonId: number().required(),
  referrer: ReferrerSchema,
  // gip: object().shape({
  //   code: string().required('GIP code is required'),
  // }),
  documents: ReferralGenericDocumentsSchema,
};

export const ReferralCreateGenericSchema = object().shape({
  ...ReferralBaseGenericSchemaObject,
  organizationId: string().required('Organisation is required'),
});

// As it is stored in the database
export const ReferralGenericSchema = ReferralBaseSchema.shape({
  ...ReferralBaseGenericSchemaObject,
  organization: OrganizationSchema,
});

export type ReferralCreateGeneric = InferType<
  typeof ReferralCreateGenericSchema
>;
export type ReferralGenericData = InferType<typeof ReferralGenericSchema>;
export type ReferralGeneric = IObject.HasIdTimestamped<ReferralGenericData>;
