import styles from './SignedIn.module.css';
import { signOut } from 'firebase/auth';
import { useAuth } from '@percihealth/react-auth';
import { useFirebase } from '@packages/web-shared';

export default function SignedIn() {
  const { user } = useAuth();
  const { auth } = useFirebase();

  if (!user) return <></>;

  return (
    <>
      <span className={styles['display-name']}>{user.displayName}</span>
      <a
        onClick={async () => {
          await signOut(auth);
          window.location.reload();
        }}
      >
        Sign-out
      </a>
    </>
  );
}
