import { InferType, string } from 'yup';
import { IObject } from '../../schemas/IObject';
import { EmailBaseSchema } from './EmailBaseSchema';

export const PauboxEmailSchema = EmailBaseSchema.shape({
  sourceTrackingId: string().required(),
});

export type PauboxEmailData = InferType<typeof PauboxEmailSchema>;
export type PauboxEmail = IObject.HasIdTimestamped<PauboxEmailData>;
