import { InferType, date, object, string } from 'yup';

export const AppointmentTranscriptionSchema = object().shape({
  path: string().required(),
  createdAt: date().required(),
});

export type AppointmentTranscription = InferType<
  typeof AppointmentTranscriptionSchema
>;
