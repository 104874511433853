export enum PositiveReturnedToWorkRationale {
  FullTime = 'full_time',
  PartTime = 'part_time',
  Phased = 'phased',
  IncreasedWorkingHours = 'increased_working_hours',
  NoInformationProvided = 'no_information_provided',
}

export const positiveReturnedToWorkRationaleToString = (
  value: PositiveReturnedToWorkRationale,
) => {
  switch (value) {
    case PositiveReturnedToWorkRationale.FullTime:
      return 'RTW full time';
    case PositiveReturnedToWorkRationale.PartTime:
      return 'RTW part time';
    case PositiveReturnedToWorkRationale.Phased:
      return 'Phased RTW';
    case PositiveReturnedToWorkRationale.IncreasedWorkingHours:
      return 'Increased working hours';
    case PositiveReturnedToWorkRationale.NoInformationProvided:
      return 'No information provided';
    default:
      return 'Invalid value';
  }
};
