import { string } from 'yup';
import { CancerStatus, cancerStatusToString } from './enums/CancerStatus';

export const IamCancerOptions = Object.values(CancerStatus).map((cs) =>
  cancerStatusToString(cs),
);

export const IamCancerOptionsWithEmptyValue = ['-', ...IamCancerOptions];

// Added an empty value '-' by default.
// If there is data sent from Healee then we will use it for the I am option.
export const IamCancerSchema = string()
  .oneOf(IamCancerOptionsWithEmptyValue)
  .test('empty-check', "'Cancer status' is required", (val) => val !== '-');
