import { object, string, date, array, InferType, bool } from 'yup';
import { nameValidation } from '@percihealth/core-js';
import { CareDocumentDataSchema } from '../CareDocumentDataSchema';
import { CareDocumentClinicalAndExperienceOutcomesSchema } from '../CareDocumentClinicalAndExperienceOutcomesSchema';
import { InterimSummaryExpertSummarySchema } from './InterimSummaryExpertSummarySchema';
import { IObject } from '../../../IObject';
import { InterimCareSummarySupportProgressSchema } from './InterimCareSummarySupportProgressSchema';

export const InterimCareSummaryDataSchema = CareDocumentDataSchema.shape({
  patient: object().shape({
    fullName: nameValidation.required('Patient name is required'),
    dob: date().required('Patient date of birth is required'),
    cancerType: string().required('Cancer type is required'),
    // dateOfDiagnosis: date().required('Date of diagnosis is required'),
    work: object().shape({
      jobTitle: string().required('Job title is required'),
      dateSignedOff: date().required('Date signed off is required'),
      workPattern: string().required('Work pattern is required'),
      hideHopingToReturnToWorkIn: bool().required(),
      hopingToReturnToWorkIn: string().when('hideHopingToReturnToWorkIn', {
        is: false,
        then: (schema) =>
          schema.required('Hoping to return to work is required'),
      }),
    }),
    level: string(),
  }),
  perciStartDate: date().required('Perci Health start date is required'),
  dateOfInterimSummary: date().required(
    'Date of interim summary date is required',
  ),

  clinicalAndExperienceOutcomes:
    CareDocumentClinicalAndExperienceOutcomesSchema.required(),

  supportProgress: array()
    .of(InterimCareSummarySupportProgressSchema)
    .required(),

  gip: object().shape({
    code: string().required('GIP code is required'),
  }),
  expertFullName: string(),
  expertSummary: InterimSummaryExpertSummarySchema.required(),
});

export type InterimCareSummaryData = InferType<
  typeof InterimCareSummaryDataSchema
>;
export type InterimCareSummary =
  IObject.HasIdTimestamped<InterimCareSummaryData>;
