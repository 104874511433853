import { object, InferType, array, string } from 'yup';
import { PcpAiGeneratedSchema } from './PcpAiGeneratedSchema';
import { AppointmentSchema } from '../../../appointments';

export const PcpAiGeneratedResponseSchema = object().shape({
  form: PcpAiGeneratedSchema.required(),
  appointmentsWithTranscriptions: array().of(AppointmentSchema).required(),
  documentsUsed: array().of(string()).required(),
});

export type PcpAiGeneratedResponse = InferType<
  typeof PcpAiGeneratedResponseSchema
>;
