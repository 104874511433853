import { InferType, bool, mixed, object, string } from 'yup';
import { RecommendedSupportStatus } from '../../../enums';

export const InitialAssessmentSummaryDataReferredProfessionalSchema =
  object().shape({
    // Whther referred in PCP
    referredEarlier: bool(),

    service: object()
      .shape({
        id: string().required(),
        name: string().required(),
        professionalType: string(),
      })
      .required('Support type is required'),

    concern: string().required('Concern is required'),

    appointmentId: string(),

    professional: object()
      .shape({
        id: string().optional(),
        name: string().optional(),
      })
      .optional()
      .default(undefined),

    status: mixed<RecommendedSupportStatus>()
      .oneOf(Object.values(RecommendedSupportStatus))
      .required(),
  });

export type InitialAssessmentSummaryDataReferredProfessional = InferType<
  typeof InitialAssessmentSummaryDataReferredProfessionalSchema
>;
