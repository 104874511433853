import { object, string, date, array, InferType } from 'yup';
import { nameValidation } from '@percihealth/core-js';
import { CareDocumentDataSchema } from '../CareDocumentDataSchema';
import { CareDocumentTreatmentDetailsSchema } from '../CareDocumentTreatmentDetailsSchema';
import { CareDocumentResourceSchema } from '../CareDocumentResourceSchema';
import { EndOfCareProfessionalSeenSchema } from './EndOfCareProfessionalSeenSchema';
import { CareDocumentClinicalAndExperienceOutcomesSchema } from '../CareDocumentClinicalAndExperienceOutcomesSchema';
import { EndOfCareSummaryReturnToWorkSchema } from './EndOfCareSummaryReturnToWorkSchema';
import { EndOfCareSummaryExpertSummarySchema } from './EndOfCareSummaryExpertSummarySchema';
import { IObject } from '../../../IObject';

export const EndOfCareSummaryDataSchema = CareDocumentDataSchema.shape({
  patient: object().shape({
    fullName: nameValidation.required('Patient name is required'),
    dob: date().required('Patient date of birth is required'),
    cancerType: string().required('Cancer type is required'),
    dateOfDiagnosis: date().required('Date of diagnosis is required'),
  }),
  perciStartDate: date().required('Perci Health start date is required'),
  endOfCareDate: date().required('End of care date is required'),

  treatmentDetails: CareDocumentTreatmentDetailsSchema.required(),
  returnToWorkStatus: EndOfCareSummaryReturnToWorkSchema.required(),
  clinicalAndExperienceOutcomes:
    CareDocumentClinicalAndExperienceOutcomesSchema.required(),
  professionalsSeen: array().of(EndOfCareProfessionalSeenSchema).required(),

  additionalSignPosting: array().of(CareDocumentResourceSchema).required(),
  additionalCommunicationNotes: string(),

  gip: object().shape({
    code: string().required('GIP code is required'),
  }),
  expertFullName: string(),
  expertSummary: EndOfCareSummaryExpertSummarySchema.required(),
  dateOfConsultation: date().required('Date of consultation is required'),
});

export type EndOfCareSummaryData = InferType<typeof EndOfCareSummaryDataSchema>;
export type EndOfCareSummary = IObject.HasIdTimestamped<EndOfCareSummaryData>;
