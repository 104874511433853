export enum NegativeReturnedToWorkRationale {
  ReadyToReturnToWork = 'ready_to_return_to_work',
  AwaitingEmployerContract = 'awaiting_employer_contract',
  DeseaseProgression = 'desease_progression',
  NoLongerInEmployment = 'no_longer_in_employment',
  NotReadyToReturnToWork = 'not_ready_to_return_to_work',
  NoInformationProvided = 'no_information_provided',
}

export const negativeReturnedToWorkRationaleToString = (
  value: NegativeReturnedToWorkRationale,
) => {
  switch (value) {
    case NegativeReturnedToWorkRationale.ReadyToReturnToWork:
      return 'Ready to RTW';
    case NegativeReturnedToWorkRationale.AwaitingEmployerContract:
      return 'Awaiting employer contact';
    case NegativeReturnedToWorkRationale.DeseaseProgression:
      return 'Desease progression';
    case NegativeReturnedToWorkRationale.NoLongerInEmployment:
      return 'No longer in employment';
    case NegativeReturnedToWorkRationale.NotReadyToReturnToWork:
      return 'Not ready to return to work at this time';
    case NegativeReturnedToWorkRationale.NoInformationProvided:
      return 'No information provided';
    default:
      return 'Invalid value';
  }
};
