import { AlertError, Button } from '@percihealth/react';
import { useRef } from 'react';
import { useUploadDocument } from '../../../../api/document';

interface Props {
  referralId: string;
  type: 'referral' | 'consent' | 'clinical-report';
  extensions: string;
  canUpload?: boolean;
  onUploaded: (e: { relativePath: string; fileName: string }) => void;
}

export const DocumentUpload = ({
  referralId,
  type,
  extensions,
  canUpload = true,
  onUploaded,
}: Props) => {
  const documentUploader = useUploadDocument();
  const uploadRef = useRef<HTMLInputElement>(null);

  const upload = async (file: File) => {
    const relativePath = `referrals/${referralId}/${type}-${Date.now()
      .toString()
      .replaceAll(':', '')}-${file.name}`;
    const isUploaded = await documentUploader.upload({
      relativePath,
      localFile: file,
    });

    if (isUploaded) {
      onUploaded({ relativePath, fileName: file.name });
    }
  };

  return (
    <div>
      <input
        type="file"
        accept={extensions}
        multiple={false}
        ref={uploadRef}
        hidden
        onChange={(e) => {
          if (e.target?.files?.length === 1) {
            upload(e.target.files[0]);
          }
        }}
      />
      <Button
        onClick={(e) => {
          e.preventDefault();
          uploadRef.current?.click();
        }}
        disabled={!referralId || !canUpload}
        submitting={documentUploader.loading}
      >
        Upload
      </Button>
      {documentUploader.errorMessage && (
        <AlertError>{documentUploader.errorMessage}</AlertError>
      )}
    </div>
  );
};
