import { object, string, date, InferType } from 'yup';
import { CareDocumentDataSchema } from './CareDocumentDataSchema';
import { IObject } from '../../IObject';

export const LandgCareDocumentDataSchema = CareDocumentDataSchema.shape({
  sentToCaseManager: object()
    .shape({
      at: date().required(),
      by: string().nullable(),
    })
    .optional()
    .default(undefined),
});

export type LandgCareDocumentData = InferType<
  typeof LandgCareDocumentDataSchema
>;
export type LandgCareDocument = IObject.HasIdTimestamped<LandgCareDocumentData>;
