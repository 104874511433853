import { InferType, boolean, object } from 'yup';
import { HealeeMedicalHistoryPatientSchema } from './HealeeMedicalHistoryPatientSchema';

export const EbpMedicalHistoryPatientSchema = object().shape({
  parsed: boolean().required(),

  sentToHealee: HealeeMedicalHistoryPatientSchema.optional().default(undefined),
});

export type EbpPatientMedicalHistory = InferType<
  typeof EbpMedicalHistoryPatientSchema
>;
