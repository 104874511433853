import { object, string, date, array, InferType } from 'yup';
import { nameValidation } from '@percihealth/core-js';
import { CareDocumentDataSchema } from '../CareDocumentDataSchema';
import { CareDocumentTreatmentDetailsSchema } from '../CareDocumentTreatmentDetailsSchema';
import { CareDocumentOvercomeChallengeSchema } from '../CareDocumentOvercomeChallengeSchema';
import { CareDocumentClinicalAndExperienceOutcomesSchema } from '../CareDocumentClinicalAndExperienceOutcomesSchema';
import { CareDocumentResourceSchema } from '../CareDocumentResourceSchema';
import { CareDocumentColumnSchema } from '../CareDocumentColumnSchema';
import { IObject } from '../../../IObject';
import { InitialAssessmentSummaryDataReferredProfessionalListSchema } from './InitialAssessmentSummaryDataReferredProfessionalListSchema';
import { InitialAssessmentSummaryWorkDetailsSchema } from './InitialAssessmentSummaryWorkDetailsSchema';

export const InitialAssessmentSummaryDataSchema = CareDocumentDataSchema.shape({
  patient: object().shape({
    fullName: nameValidation.required('Patient name is required'),
    dob: date().required('Patient date of birth is required'),
    cancerType: string().required('Cancer type is required'),
    dateOfDiagnosis: date().required('Date of diagnosis is required'),
    work: InitialAssessmentSummaryWorkDetailsSchema,
    level: string(),
  }),
  perciStartDate: date().required('Perci Health start date is required'),
  gip: object().shape({
    code: string().required('GIP code is required'),
  }),

  dateOfConsultation: date().required('Date of consultation is required'),

  hna: array().of(CareDocumentColumnSchema).required(),

  treatmentDetails: CareDocumentTreatmentDetailsSchema.required(),

  planToOvercomeChallenges: array()
    .of(CareDocumentOvercomeChallengeSchema)
    .required(),

  clinicalAndExperienceOutcomes:
    CareDocumentClinicalAndExperienceOutcomesSchema.required(),

  professionalsReferred:
    InitialAssessmentSummaryDataReferredProfessionalListSchema,

  expertFullName: string(),
  expertSummary: string().required(
    'Cancer nurse specialist summary is required',
  ),

  signPosting: array().of(CareDocumentResourceSchema).required(),
  communicationNotes: string(),
});

export type InitialAssessmentSummaryData = InferType<
  typeof InitialAssessmentSummaryDataSchema
>;
export type InitialAssessmentSummary =
  IObject.HasIdTimestamped<InitialAssessmentSummaryData>;
