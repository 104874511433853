import { EbpCaseSchema } from './EbpCaseSchema';
import { InferType } from 'yup';
import { IObject } from '../IObject';

export const EbpLandgCaseSchema = EbpCaseSchema.shape({});

export type EbpLandgCaseData = InferType<typeof EbpLandgCaseSchema> & {};

export type EbpLandgCase = IObject.HasIdTimestamped<
  InferType<typeof EbpLandgCaseSchema>
>;
