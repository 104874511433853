import { AppointmentReferralReviewSchema } from './AppointmentReferralReviewSchema';
import { InferType, array, boolean, date, number, object, string } from 'yup';

export const AppointmentConclusionSchema = object().shape({
  date: date(),
  notesToPatient: string(),
  // Optionally a doctor can refer (suggest) an appointment to a specialist.
  // The specialist should approve it and then manually go to Healee and create an appointment.
  // https://miro.com/app/board/uXjVMyBaM2M=/
  requestedReferral: object()
    .shape({
      services: array().of(number()),
      notes: string(), // doctor notes
      followUpRequired: boolean(),
      withinWeeks: string(),
      review: AppointmentReferralReviewSchema.default(undefined),
    })
    .default(undefined),
});

export type AppointmentConclusion = InferType<
  typeof AppointmentConclusionSchema
>;
