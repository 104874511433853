import { createContext } from 'react';
import { FirebaseApp } from 'firebase/app';
import { Firestore } from 'firebase/firestore';
import { FirebaseStorage } from 'firebase/storage';
import { Auth } from 'firebase/auth';

export const FirebaseContext = createContext<{
  firebaseApp: FirebaseApp;
  storage: FirebaseStorage;
  firestore: Firestore;
  auth: Auth;
}>({
  firebaseApp: null as any,
  storage: null as any,
  firestore: null as any,
  auth: null as any,
});
