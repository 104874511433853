import { emailValidation, nameValidation } from '@percihealth/core-js';
import { InferType, object } from 'yup';

export const ReferrerSchema = object().shape({
  firstname: nameValidation.required('Referrer first name is required'),
  lastname: nameValidation.required('Referrer surname is required'),
  email: emailValidation.required('Referrer email is required'),
});

export type Referrer = InferType<typeof ReferrerSchema>;
