import { emailValidation } from '@percihealth/core-js';
import { date, mixed, object, string } from 'yup';
import { EbpMedicalHistoryPatientSchema } from '../medicalHistory/EbpMedicalHistoryPatientSchema';
import { CancerStatus } from '../enums';

export const CreateEbpPatientSchema = object().shape({
  shortId: string().nullable().notRequired(),
  email: emailValidation,
  firstName: string().nullable().notRequired(),
  lastName: string().nullable().notRequired(),
  dateOfBirth: date().nullable().notRequired(),
  cancerStatus: mixed<CancerStatus>().oneOf(Object.values(CancerStatus)),
});

// Update from Healee PUT request or from UI in the EBP Admin or
export const UpdateEbpPatientSchema =
  // All CreateEbpPatientSchema can be updated by Healee
  CreateEbpPatientSchema.shape({
    // The following fields can be updated from EBP Admin UI
    level: string().max(30).optional(),
  });

export const EbpPatientSchema = UpdateEbpPatientSchema.shape({
  medicalHistory: EbpMedicalHistoryPatientSchema.optional()
    .nullable()
    .default(undefined),
});
