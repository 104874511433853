import { object, string, InferType, number } from 'yup';
import { ReferralPatientSchema } from '../ReferralPatientSchema';
import { ReferralLandgCaseManagerSchema } from './ReferralLandgCaseManagerSchema';
import { IObject } from '../../IObject';
import { ReferralBaseSchema } from '../ReferralBaseSchema';
import { ReferralLandgDocumentsSchema } from './ReferralLandgDocumentsSchema';

const ReferralBaseLandgSchemaObject = {
  // ID is required for L&G referrals to be passed from UI
  // because a referrer uploads documents to the storage "referrals/{generated-referral-id}/document.pdf"
  id: string().required(),
  patient: ReferralPatientSchema,
  joinReasonId: number().required(),
  caseManager: ReferralLandgCaseManagerSchema,
  gip: object().shape({
    code: string().required('GIP code is required'),
  }),
  documents: ReferralLandgDocumentsSchema,
};

export const ReferralCreateLandgSchema = object().shape(
  ReferralBaseLandgSchemaObject,
);

// As it is stored in the database
export const ReferralLandgSchema = ReferralBaseSchema.shape({
  ...ReferralBaseLandgSchemaObject,
});

export type ReferralCreateLandg = InferType<typeof ReferralCreateLandgSchema>;
export type ReferralLandgData = InferType<typeof ReferralLandgSchema>;
export type ReferralLandg = IObject.HasIdTimestamped<ReferralLandgData>;
