import { InferType, mixed, object } from 'yup';
import { IObject } from '../IObject';
import { EmailEventRecordType } from '../enums';

export const EmailEventDataSchema = object().shape({
  RecordType: mixed<EmailEventRecordType>().required(),
});

export type EmailEventData = InferType<typeof EmailEventDataSchema>;
export type EmailEvent = IObject.HasIdTimestamped<EmailEventData>;
