export const ebpCaseEmails = {
  PcpToHealixNurse: {
    id: 'ebp-pcp-to-nurse',
    name: 'PCP to Healix nurse',
  },
  ToLandgCaseManagerDocument: {
    id: 'ebp-document-to-landg-case-manager',
    name: 'To L&G case manager "Document"',
  },
  ToLandgCaseManagerFeedback: {
    id: 'ebp-to-landg-case-manager-feedback',
    name: 'To L&G case manager "Feedback"',
  },
  // The template can be changed to be shared with other organizations, so the id is taken generic
  ToLandgClientDischarged: {
    id: 'ebp-to-client-partner-discharged',
    name: 'To L&G client "Discharged"',
  },
  // The template can be changed to be shared with other organizations, so the id is taken generic
  ToLandgClientFeedback: {
    id: 'ebp-to-client-partner-feedback',
    name: 'To L&G client "Member feedback"',
  },
};

export const ebpCaseEmailIds = Object.values(ebpCaseEmails).map((v) => v.id);
