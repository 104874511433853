import { object, string, boolean, InferType, number } from 'yup';
import { ReferrerHealixSchema } from './ReferrerHealixSchema';
import { ReferralHealixPatientSchema } from './ReferralHealixPatientSchema';
import { IObject } from '../../IObject';
import { ReferralBaseSchema } from '../ReferralBaseSchema';

// Healix referral that is sent from Healix system via REST API
const HealixBaseSchemaObject = {
  patient: ReferralHealixPatientSchema,
  referrer: ReferrerHealixSchema,
  details: string()
    .required('Description is required')
    .max(4000, 'Description cannot be longer than 4000 characters'),
  joinReasonId: number().required('Join reason ID is required'),
  source: string(), // Healix API or Referral web site
};
export const HealixReferralInterSystemSchema = object().shape(
  HealixBaseSchemaObject,
);

// Healix Referrral that is sent via Web UI
export const ReferralCreateHealixSchema = HealixReferralInterSystemSchema.shape(
  {
    acceptForm: boolean()
      .required()
      .oneOf([true], 'Please confirm having the consent'),
  },
);

// Healix Referral how it stored in the database
export const ReferralHealixSchema = ReferralBaseSchema.shape({
  // override default Referral fiels with the Healix types
  ...HealixBaseSchemaObject,
});

export type HealixReferralInterSystem = InferType<
  typeof HealixReferralInterSystemSchema
>;

export type ReferralHealixData = InferType<typeof ReferralHealixSchema>;
export type ReferralHealix = IObject.HasIdTimestamped<ReferralHealixData>;
export type ReferralCreateHealix = InferType<typeof ReferralCreateHealixSchema>;
