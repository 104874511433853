import React from 'react';
import ReactDOM from 'react-dom/client';
import '@percihealth/react/css/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { getStorage, connectStorageEmulator } from 'firebase/storage';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

if (
  location.hostname === 'localhost' &&
  process.env.REACT_APP_BACKEND_URL!.includes('localhost')
) {
  const storage = getStorage();
  // Point to the Storage emulator running on localhost.
  connectStorageEmulator(storage, '127.0.0.1', 9199);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
