import { InferType, object, string } from 'yup';

export const EndOfCareSummaryExpertSummarySchema = object().shape({
  motivation: string().required(
    'Motivation and programme engagement is required',
  ),
  progressAgainstGoals: string().required(
    'Progress against goals and symptom/problem management is required',
  ),
  assessmentOfCapabilitiesReturnToWork: string().required(
    'Assessment of capability to return back to work is required',
  ),
});

export type EndOfCareSummaryExpertSummary = InferType<
  typeof EndOfCareSummaryExpertSummarySchema
>;
