import { InferType, mixed, object } from 'yup';
import { EbpCaseProgressColor } from '../enums/EbpCaseProgressColor';

const progressColorSchema = mixed<EbpCaseProgressColor>().oneOf(
  Object.values(EbpCaseProgressColor),
);

export const EbpCaseProgressSchema = object().shape({
  stage1: progressColorSchema.required(),
  stage2: progressColorSchema.required(),
  stage3: progressColorSchema.required(),
  stage4: progressColorSchema.required(),
  stage5: progressColorSchema.required(),
});

export type EbpCaseProgress = InferType<typeof EbpCaseProgressSchema>;

export const DefaultEbpCaseProgress = {
  stage1: EbpCaseProgressColor.Grey,
  stage2: EbpCaseProgressColor.Grey,
  stage3: EbpCaseProgressColor.Grey,
  stage4: EbpCaseProgressColor.Grey,
  stage5: EbpCaseProgressColor.Grey,
} satisfies EbpCaseProgress;
