import {
  emailValidation,
  nameValidation,
  phoneValidation,
} from '@percihealth/core-js';
import { ReferralPatientSchema } from '../ReferralPatientSchema';
import { InferType, date, string } from 'yup';
import { ReferralHealixPatientGenderSchema } from './ReferralHealixPatientGenderSchema';

export const ReferralHealixPatientSchema = ReferralPatientSchema.shape({
  firstname: nameValidation.required('Patient first name is required'),
  lastname: nameValidation.required('Patient surname is required'),
  email: emailValidation.required('Patient email is required'),
  phone: phoneValidation,
  gender: ReferralHealixPatientGenderSchema,
  dob: date(),
  address: string().max(256, 'Patient must be at most 256 characters'),
  postcode: string().max(24, 'Patient must be at most 24 characters'),
});

export type ReferralHealixPatient = InferType<
  typeof ReferralHealixPatientSchema
>;
