import { nameValidation } from '@percihealth/core-js';
import { object, string, mixed, date, InferType } from 'yup';
import { CareDocumentType } from '../../enums';
import { IObject } from '../../IObject';

export const CareDocumentDataSchema = object().shape({
  type: mixed<CareDocumentType>()
    .oneOf(Object.values(CareDocumentType))
    .required(),
  caseId: string().required(),
  storageRef: string(),
  patient: object().shape({
    fullName: nameValidation.required('Patient name is required'),
  }),
  uploadedToHealee: object()
    .shape({
      at: date().required(),
      by: string().nullable(),
    })
    .optional()
    .default(undefined),
});

export type CareDocumentData = InferType<typeof CareDocumentDataSchema>;
export type CareDocument = IObject.HasIdTimestamped<CareDocumentData>;
