import { object, string, InferType, date } from 'yup';
import { IamCancerSchema } from '../../../IamCancerSchema';
import { PcpPatientServiceListSchema } from '../pcp';

export const PcpAiGeneratedSchema = object().shape({
  patient: object()
    .shape({
      iam: IamCancerSchema.nullable(),
    })
    .nullable(),
  dateOfConsultation: date().nullable(),
  notes: string().nullable(),
  patientServices: PcpPatientServiceListSchema,
});

export type PcpAiGenerated = InferType<typeof PcpAiGeneratedSchema>;
