import { ref, uploadBytes } from 'firebase/storage';
import { useState } from 'react';
import { useFirebase } from '@packages/web-shared';

export const useUploadDocument = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { storage } = useFirebase();

  const upload = async ({
    relativePath,
    localFile,
  }: {
    relativePath: string;
    localFile: File;
  }) => {
    const fileRef = ref(storage, relativePath);

    try {
      setLoading(true);
      setErrorMessage(null);
      await uploadBytes(fileRef, localFile);

      return true;
    } catch (err) {
      const errorMsg = `Cannot upload the file ${localFile.name ?? ''}`;
      console.error(errorMsg);
      console.error(err);
      setErrorMessage(errorMsg);
    } finally {
      setLoading(false);
    }
    return false;
  };

  return { upload, loading, errorMessage };
};
