import { useMemo, useState } from 'react';
import { FirebaseApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import { FirebaseContext } from './FirebaseContext';
import { getAuth } from 'firebase/auth';

export const FirebaseProvider = ({
  firebaseApp,
  children,
}: {
  firebaseApp: FirebaseApp;
  children: JSX.Element;
}) => {
  const [storage] = useState(getStorage(firebaseApp));
  const [firestore] = useState(getFirestore(firebaseApp));
  const [auth] = useState(getAuth(firebaseApp));

  const values = useMemo(
    () => ({
      storage,
      firestore,
      firebaseApp,
      auth,
    }),
    [storage, firestore, firebaseApp, auth],
  );

  return (
    <FirebaseContext.Provider value={values}>
      {children}
    </FirebaseContext.Provider>
  );
};
