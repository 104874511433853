import { InferType, array, mixed, string } from 'yup';
import { IObject } from '../IObject';
import { EmailEventDataSchema, EmailBaseSchema } from '../emails';
import { EmailEventRecordType } from '../enums';

export const EbpCaseEmailDataSchema = EmailBaseSchema.shape({
  caseId: string(),
  status: mixed<EmailEventRecordType>().required(),
  events: array().of(EmailEventDataSchema).default(undefined),
  documentId: string(),
});

export type EbpCaseEmailData = InferType<typeof EbpCaseEmailDataSchema>;
export type EbpCaseEmail = IObject.HasIdTimestamped<EbpCaseEmailData>;
