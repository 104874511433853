export enum EmailEventRecordType {
  SentToPostmark = 'SentToPostmark',
  SentToPaubox = 'SentToPaubox',
  Bounce = 'Bounce',
  HardBounce = 'HardBounce',
  Delivery = 'Delivery',
  SpamComplaint = 'SpamComplaint',
  Open = 'Open',
  Error = 'Error',
}
