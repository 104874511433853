import { InferType, date, mixed, object, string } from 'yup';
import { ReferralCancelOptionsSchema } from './ReferralCancelOptionsSchema';
import { ReferralOrganizationEmailOptionsSchema } from './ReferralOrganizationEmailOptionsSchema';
import { IObject } from '../IObject';
import { ReferralStatus, ReferralType } from '../enums';

const ReferralEmailLineSchema = object().shape({ status: string() });
type ReferralEmailLine = InferType<typeof ReferralEmailLineSchema>;

// Fields that are used in all referrals (Default, Healix, L&G)
export const ReferralBaseSchema = object().shape({
  type: mixed<ReferralType>().oneOf(Object.values(ReferralType)).required(),
  status: mixed<ReferralStatus>()
    .oneOf(Object.values(ReferralStatus))
    .required(),
  source: string(),

  cancelReason: object()
    .shape({
      key: string().required(),
      value: string().required(),
    })
    .default(undefined),
  cancelOptions: ReferralCancelOptionsSchema,
  service: object()
    .shape({
      id: string(),
      name: string().required(),
      healee_ref: string().required(),
      professional_type: string().required(),
    })
    .default(undefined),

  emails: object<Record<string, ReferralEmailLine>>(),
  emailOptions: ReferralOrganizationEmailOptionsSchema,

  pausedAt: date(),
});

export type ReferralBaselData = InferType<typeof ReferralBaseSchema>;
export type ReferralBase = IObject.HasIdTimestamped<ReferralBaselData>;

export const ReferralUpdateSchema = ReferralBaseSchema.pick(['emailOptions']);
export type ReferralUpdate = InferType<typeof ReferralUpdateSchema>;
