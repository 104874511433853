import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
} from 'react-router-dom';
import LandgReferral from './components/pages/LandgReferral';
import { ReferredLandgPage } from './components/pages/ReferredLandgPage';
import { ScrollToTop } from '@percihealth/react';
import LandgLoginPage from './components/pages/LandgLoginPage';
import Layout from './components/shared/Layout/Layout';
import {
  AskForRightsPage,
  AuthProvider,
  PrivateElement,
} from '@percihealth/react-auth';
import firebaseApp from './firebase';
import { FirebaseProvider } from '@packages/web-shared';

export default function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        element={
          <Layout>
            <ScrollToTop />
            <Outlet />
          </Layout>
        }
      >
        <Route
          path="/"
          element={
            <PrivateElement>
              <LandgReferral />
            </PrivateElement>
          }
        />
        <Route
          path="referred"
          element={
            <PrivateElement>
              <ReferredLandgPage />
            </PrivateElement>
          }
        />
        <Route path="/login" element={<LandgLoginPage />} />
        <Route path="/ask-for-rights" element={<AskForRightsPage />} />
      </Route>,
    ),
  );

  return (
    <AuthProvider firebaseApp={firebaseApp}>
      <FirebaseProvider firebaseApp={firebaseApp}>
        <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />
      </FirebaseProvider>
    </AuthProvider>
  );
}
