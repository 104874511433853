import { object, string, array, date, boolean, InferType } from 'yup';

// Special structure for Referral documents copied into EBP
export const ReferralLandgDocumentsSchema = object().shape({
  // all documents are successfully converted from Word to PDF in Referrals,
  // and AI can parse them
  readyForAnylize: boolean(),
  latestClinicalReport: object()
    .shape({
      name: string().required('Latest clinical report is required'),
      path: string().required(),
      uploadedToHealee: object()
        .shape({
          at: date().required(),
          by: string().nullable(),
        })
        .optional()
        .default(undefined),
    })
    .required(),
  referrals: array()
    .of(
      object().shape({
        name: string().required('Document name is required'),
        path: string().required(),
        uploadedToHealee: object()
          .shape({
            at: date().required(),
            by: string().nullable(),
          })
          .optional()
          .default(undefined),
      }),
    )
    .max(20, 'Maximum can be uploaded 20 documents'),
  consent: object()
    .shape({
      name: string().required('Consent form is required'),
      path: string().required(),
    })
    .required(),
});

export type ReferralLandgDocuments = InferType<
  typeof ReferralLandgDocumentsSchema
>;
