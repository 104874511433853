import Header from './Header/Header';
import styles from './Layout.module.css';
// import FooterMainsite from '@packages/web-shared/components/Layout/Footer/FooterMainsite';
import Footer from './Footer/Footer';

export default function Layout(props: { children: any }) {
  return (
    <div className={styles.layout}>
      <Header />
      {props.children}
      <Footer />
      {/* <FooterMainsite className={styles.footer} /> */}
    </div>
  );
}
