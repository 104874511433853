import { InferType, object, string } from 'yup';
import { CareDocumentOutcomeSchema } from './CareDocumentOutcomeSchema';

export const CareDocumentClinicalAndExperienceOutcomesSchema = object().shape({
  mycawConcern1: CareDocumentOutcomeSchema.shape({
    concern: string(),
  }).required(),
  mycawConcern2: CareDocumentOutcomeSchema.shape({
    concern: string(),
  }).required(),
  overallWellbeing: CareDocumentOutcomeSchema.required(),
  otherFactors: CareDocumentOutcomeSchema.optional().default(undefined),
  mostImportantAspectOfSupport:
    CareDocumentOutcomeSchema.optional().default(undefined),
  returnToWorkSelfEfficacy: CareDocumentOutcomeSchema.required(),
  fatigue: CareDocumentOutcomeSchema.required(),
});

export type CareDocumentClinicalAndExperienceOutcomes = InferType<
  typeof CareDocumentClinicalAndExperienceOutcomesSchema
>;
