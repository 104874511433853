import { Appointment } from '../appointments';
import { AppointmentStatus } from './AppointmentStatus';

export enum ReferralStatus {
  initiated = 'initiated',
  sent = 'sent',
  booked = 'booked',
  appointment_cancelled = 'appointment_cancelled',
  paused = 'paused',
  cancelled = 'cancelled',
  completed = 'completed',
}

export const convertAppointmentToReferralStatus = (
  appointments: Appointment[],
) => {
  if (appointments.length === 0) {
    return null;
  }

  if (
    appointments.some(
      (apt) => apt.status === AppointmentStatus.completed && !apt.noShow,
    )
  ) {
    return ReferralStatus.completed;
  } else if (
    appointments.some(
      (apt) =>
        apt.status === AppointmentStatus.booked ||
        apt.status === AppointmentStatus.rescheduled,
    )
  ) {
    return ReferralStatus.booked;
  } else {
    return ReferralStatus.appointment_cancelled;
  }
};

export const canReferralBePaused = (status: ReferralStatus) => {
  if (
    [
      ReferralStatus.initiated,
      ReferralStatus.sent,
      ReferralStatus.appointment_cancelled,
    ].includes(status)
  ) {
    return { allow: true, error: undefined };
  }

  return {
    allow: false,
    error:
      'referral can be paused only in the "sent" and "appointment_cancelled" statuses',
  };
};
