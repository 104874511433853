import { InferType, boolean, date, mixed, object, string } from 'yup';
import { OrganizationSchema } from '../OrganizationSchema';
import { CareDocumentRefSchema } from './care-documents';
import {
  CreateEbpPatientSchema,
  EbpPatientSchema,
  UpdateEbpPatientSchema,
} from './EbpPatientSchema';
import { EbpCaseDischargeOptionsSchema } from './EbpCaseDischargeOptionsSchema';
import { IObject } from '../IObject';
import { EbpCaseStatus } from '../enums';
import { EbpCaseReturnedToWorkSchema } from './EbpCaseReturnedToWorkSchema';
import { EbpCaseProgressSchema } from './EbpCaseProgressSchema';
import { EbpCaseSupportSchema } from './support/EbpCaseSupportSchema';
import { EbpCaseAppointmentsSchema } from './EbpCaseAppointmentsSchema';

// As it is sent from UI or API requests
export const CreateEbpCaseSchema = object().shape({
  patient: CreateEbpPatientSchema,
  organizationId: string().nullable().notRequired(),
  expertId: string().nullable().notRequired(),
});

export const UpdateEbpCaseSchema = object().shape({
  patient: UpdateEbpPatientSchema,
  expertId: string().nullable().notRequired(),
  consentShareDataToInsurer: boolean(),
  improvedKeyChallenge: boolean().nullable().notRequired(),
  followedUpAfterPcp: boolean(),
  returnedToWork: EbpCaseReturnedToWorkSchema.nullable()
    .optional()
    .default(undefined),
  estimatedDischargeDate: date().notRequired(),
});

// As it is stored in the database
export const EbpCaseSchema = UpdateEbpCaseSchema.pick([
  'expertId',
  'consentShareDataToInsurer',
  'improvedKeyChallenge',
  'followedUpAfterPcp',
  'returnedToWork',
  'estimatedDischargeDate',
]).shape({
  status: mixed<EbpCaseStatus>().oneOf(Object.values(EbpCaseStatus)).required(),
  patient: EbpPatientSchema,
  // calculated field
  organization: OrganizationSchema,
  hasAppointment: boolean(),
  appointments: EbpCaseAppointmentsSchema,

  // calculated field
  followedUp: boolean(),
  forms: object().shape({
    mycawInitialFormId: string(),
    mycawFollowUpFormId: string(),
    mdtFeedbackFormId: string(),
    // obsolete, divided on two: rtw-se and fatigue
    workFocusedFormId: string(),
    rtwSeFormId: string(),
    fatigueFormId: string(),
  }),
  documents: object().shape({
    pcp: CareDocumentRefSchema,
    workFocusedPlan: CareDocumentRefSchema,
    endOfCareSummary: CareDocumentRefSchema,
    interimCareSummary: CareDocumentRefSchema,
    initialAssessmentSummary: CareDocumentRefSchema,
  }),
  followedUpAfterPcp: boolean(),
  referralId: string(),
  // a referral was sent before the case creation, so we can differentiate the self-referrred cases
  hasReferralBeforeCase: boolean(),
  dischargeOptions: EbpCaseDischargeOptionsSchema,
  dischargedAt: date().nullable().default(undefined),

  support: EbpCaseSupportSchema,

  progress: EbpCaseProgressSchema,
});

export type CreateEbpCase = InferType<typeof CreateEbpCaseSchema>;
export type UpdateEbpCase = InferType<typeof UpdateEbpCaseSchema>;
export type EbpCase = IObject.HasIdTimestamped<InferType<typeof EbpCaseSchema>>;
