import { InferType, array, date, object, string } from 'yup';

export const EndOfCareProfessionalSeenSchema = object().shape({
  service: object()
    .shape({
      id: string().required(),
      name: string(),
      professionalType: string(),
    })
    .required('Support type is required'),
  appointments: array()
    .of(
      object().shape({
        id: string().required(),
        date: date(),
        professionalName: string(),
      }),
    )
    .required(),
});

export type EndOfCareProfessionalSeen = InferType<
  typeof EndOfCareProfessionalSeenSchema
>;
